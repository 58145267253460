import ClubConfig from '@/common/clubsConfig';

// const ClubConfig = () =>
//   import(
//     '../common/clubsConfig'
//     );

function slugDecoder(slug) {
  return slug.split('-los-cabos')[0];
}

function venueDecoder(title) {
  let venue = title.split('@')[1];
  if (!venue) {
    return { title: title,  };
  }
  if (venue.toLowerCase().match(/bagatelle/)) {
    return { slug: '/los-cabos-clubs/bagatelle-los-cabos', title: 'Bagatelle Cabo',
      logo: ClubConfig['bagatelle-los-cabos'].logo
    };
  }
  if (venue.toLowerCase().match(/taboo/)) {
    return { slug: '/los-cabos-clubs/taboo-los-cabos', title: 'Taboo Cabo',
      logo: ClubConfig['taboo-los-cabos'].logo
    };
  }
  if (venue.toLowerCase().match(/crania/)) {
    return { slug: '/los-cabos-clubs/crania-los-cabos', title: 'Crania Cabo',
      logo: ClubConfig['crania-los-cabos'].logo
    };
  }
  if (venue.toLowerCase().match(/rosa/)) {
    return { slug: '/los-cabos-clubs/rosa-negra-los-cabos', title: 'Rosanegra Cabo',
      logo: ClubConfig['rosa-negra-los-cabos']?.logo
    };
  }
  if (venue.toLowerCase().match(/chambao/)) {
    return { slug: '/los-cabos-clubs/chambao-los-cabos', title: 'Chambao Los Cabos',
      logo: ClubConfig['chambao-los-cabos']?.logo
    };
  }
  if (venue.toLowerCase().match(/mamazzita/)) {
    return { slug: '/los-cabos-clubs/mamazzita-los-cabos', title: 'Mamazzita Los Cabos',
      logo: ClubConfig['mamazzita-los-cabos']?.logo
    };
  }
  if (venue.toLowerCase().match(/mandala/)) {
    return { slug: '/los-cabos-clubs/mandala-los-cabos', title: 'Mamazzita Los Cabos',
      logo: ClubConfig['mandala-los-cabos']?.logo
    };
  }
  if (venue.toLowerCase().match(/geisha/)) {
    return { slug: '/los-cabos-clubs/funky-geisha-los-cabos', title: 'Funky Geisha Los Cabos',
      logo: ClubConfig['funky-geisha-los-cabos']?.logo
    };
  }
  if (venue.toLowerCase().match(/craft/)) {
    return { slug: '/los-cabos-clubs/craft-los-cabos', title: 'Craft Los Cabos',
      logo: ClubConfig['craft-los-cabos']?.logo
    };
  }
  if (venue.toLowerCase().match(/tamara/)) {
    return { slug: '/los-cabos-clubs/tamara-beach-club-los-cabos', title: 'Tamara Los Cabos',
      logo: ClubConfig['tamara-beach-club-los-cabos']?.logo
    };
  }

  return { title: venue };
}

function brandDecoder(title) {
  let brand = title.toLowerCase();
  if (brand.match(/vagalume sessions/)) {
    return { slug: 'vagalume-sessions-tulum', title: 'Vagalume Sessions' };
  }
  if (brand.match(/zamna/)) {
    return { slug: 'zamna-tulum', title: 'ZAMNA' };
  }
  if (brand.match(/dpm/)) {
    return { slug: 'dpm-global-tulum', title: 'DPM GLOBAL' };
  }
}

export { brandDecoder, venueDecoder, slugDecoder };
